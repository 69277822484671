import WebMap from "@arcgis/core/WebMap";

export function constructWebMap(webMapId: string): WebMap {
  const webmap = new WebMap({
    portalItem: {
      // autocasts as new PortalItem()
      id: webMapId,
    },
  });
  return webmap;
}
