import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import Expand from "@arcgis/core/widgets/Expand";
import LayerList from "@arcgis/core/widgets/LayerList";
import Home from "@arcgis/core/widgets/Home";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Compass from "@arcgis/core/widgets/Compass";
import Search from "@arcgis/core/widgets/Search";
import { SampleWidget } from "../../components/SampleWidget/SampleWidget";
import { MyButton } from "../../components/SampleWidget/MyButton";
import { KeyPressed } from "../../components/SampleWidget/KeyPressed";
import { TextDescription } from "../../components/TextDescription/TextDescription";
import { TextDescriptionButton } from "../../components/TextDescription/TextDescriptionButton";
import { constructReactWidget } from "../../utils/constructReactWidget";
import { AppVM } from "./AppVM";

export function constructMapView(map: Map, appVM: AppVM): MapView {
  const mapView = new MapView({
    map: map,
    // center: [-95, 40], // CONTINENTAL US
    // zoom: 5, // CONTINENTAL US
    constraints: {
      rotationEnabled: false,
    },
  });

  mapView.ui.remove("attribution");

  const home = new Home({ view: mapView });
  mapView.ui.add(home, "top-left");

  // const compass = new Compass({ view: mapView });
  // mapView.ui.add(compass, "top-left");

  const layerList = new LayerList({ view: mapView });
  const layerListExpand = new Expand({
    view: mapView,
    content: layerList,
    expandTooltip: "Layer List",
    collapseTooltip: "Layers List",
  });
  mapView.ui.add(layerListExpand, "top-left");

  const basemapGallery = new BasemapGallery({ view: mapView });
  const basemapGalleryExpand = new Expand({
    view: mapView,
    content: basemapGallery,
    expandTooltip: "Basemap Gallery",
    collapseTooltip: "Basemap Gallery",
  });
  mapView.ui.add(basemapGalleryExpand, "top-left");

  const disableMouseButton = new Expand({
    view: mapView,
    expandTooltip: "Mouse Control Enabled",
    collapseTooltip: "Mouse Control Disabled",
    expandIconClass: "esri-icon-cursor-filled",
    collapseIconClass: "esri-icon-cursor",
  });
  mapView.ui.add(disableMouseButton, "top-left");
  disableMouseButton.watch("expanded", (newValue) => {
    appVM.disableMouseInput = newValue;
  });

  // const search = new Search({ view: mapView });
  // const searchExpand = new Expand({
  //   view: mapView,
  //   content: search,
  // });
  // mapView.ui.add(searchExpand, "top-left");

  // const myWidget = constructReactWidget(SampleWidget, { vm: appVM });
  // mapView.ui.add(myWidget, "top-right");

  // const myButton = constructReactWidget(MyButton, { vm: appVM });
  // mapView.ui.add(myButton, "top-right");

  const keyPressed = constructReactWidget(KeyPressed, { vm: appVM });
  mapView.ui.add(keyPressed, "bottom-left");

  // const myTextDescription = constructReactWidget(TextDescription, {
  //   vm: appVM,
  // });
  // mapView.ui.add(myTextDescription, "top-right");

  // const myTextDescriptionButton = constructReactWidget(TextDescriptionButton, {
  //   vm: appVM,
  // });
  // mapView.ui.add(myTextDescriptionButton, "top-right");

  // DISABLE UNWANTED MAP INPUT BEHAVIOR
  // disable mouse wheel events
  mapView.on("mouse-wheel", (event) => {
    // alert("To zoom in please  click the map. Use zoom +/- buttons.");
    if (appVM.disableMouseInput) {
      event.stopPropagation();
    }
  });

  //disable mouse click events
  mapView.on("click", (event) => {
    if (appVM.disableMouseInput) {
      event.stopPropagation();
    }
  });

  //disable mouse click events
  mapView.on("double-click", (event) => {
    if (appVM.disableMouseInput) {
      event.stopPropagation();
    }
  });

  //disable mouse click events
  mapView.on("drag", (event) => {
    // alert("To navigate please  click the map. Use zoom arrow buttons.");
    if (appVM.disableMouseInput) {
      event.stopPropagation();
    }
  });

  // //disable popups
  // mapView.when(() => {
  //   mapView.map.allLayers.forEach((layer: any) => {
  //     if (layer.popupEnabled) {
  //       layer.popupEnabled = false;
  //     }
  //   });
  // });

  return mapView;
}
