import { makeAutoObservable } from "mobx";
import MapView from "@arcgis/core/views/MapView";
import { ExtentHandler } from "../ExtentHandler";
import { constructMapView } from "./constructMapView";
import { constructMap } from "./constructMap";
import { constructWebMap } from "./constructWebMap";
import esriId from "@arcgis/core/identity/IdentityManager";
import { CoursesDataset } from "../CoursesDataset/CoursesDataset";

//TODO: update this id with value provided by webmap author
// const __webMapId = "e121473423534d77bfb1630c3d638bcd";
const __webMapId = "4c63034b7a8b441598516e8e49ad233a";

//https://services7.arcgis.com/1hCPih9sRKKEQwws/arcgis/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer

export class AppVM {
  public initialized: boolean = false;
  public mapView?: MapView;
  public extentHandler?: ExtentHandler;
  public someValue?: string = "HELLO";
  public dynamicMapDescription?: string = "";

  public currentKeyPressed?: string;
  public coursesDataset?: CoursesDataset;

  public keyHistory: string[] = [];

  public disableMouseInput: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async initialize(): Promise<void> {
    this.initialized = false;

    // esriId.registerToken({
    //   server: "https://www.arcgis.com/sharing/rest/",
    //   token:
    //     "Mdl_is2cAOYXY8OZt63ouVLO22CfKjSp5-22HZRD2-mes76M0Tha1CX_Nrcn8DBQysfXyE__8uBfPA5G3HDfHy3fS5qKbI2Qp9NAi7R00eGZWOp3EK7VbGZoLnuOQwsFujXhXC-13-Ma3xsZhxWEDg..",
    // });

    const map = constructWebMap(__webMapId);
    //const map = constructMap();
    this.mapView = constructMapView(map, this);
    this.extentHandler = new ExtentHandler(this);
    this.initialized = true;
  }
}
