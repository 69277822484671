import React from "react";
import { observer } from "mobx-react";

export const NavigationHelp = observer(() => {
  return (
    <table className="navigationHelpTable">
      <tr>
        <th>Key</th>
        <th>Function</th>
      </tr>
      <tr>
        <td>
          <strong>+ (PLUS_KEY)</strong>
        </td>
        <td>Zoom In</td>
      </tr>
      <tr>
        <td>
          <strong>- (MINUS_KEY)</strong>
        </td>
        <td>Zoom Out</td>
      </tr>
      <tr>
        <td>
          <strong>ArrowUp</strong>
        </td>
        <td>Pan North</td>
      </tr>
      <tr>
        <td>
          <strong>ArrowRight</strong>
        </td>
        <td>Pan East</td>
      </tr>
      <tr>
        <td>
          <strong>ArrowDown</strong>
        </td>
        <td>Pan South</td>
      </tr>
      <tr>
        <td>
          <strong>ArrowLeft</strong>
        </td>
        <td>Pan West</td>
      </tr>
    </table>
  );
});
