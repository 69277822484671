import React, { Fragment } from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { AppVM } from "../../model/AppVM/AppVM";

interface IkeyPressedProps {
  vm: AppVM;
}

let timeout: any;

export const KeyPressed = observer((props: IkeyPressedProps) => {
  useEffect(() => {
    // document.addEventListener('keydown', detectKeyDown, true)
    props.vm.mapView?.on("key-down", detectKeyDown);
  }, []);

  const detectKeyDown = (e) => {
    // if (timeout) {
    //   clearTimeout(timeout);
    //   timeout = undefined;
    // }
    // timeout = setTimeout(() => {
    //   props.vm.currentKeyPressed = "";
    //   timeout = undefined;
    // }, 1500);

    let key = e.key;
    if (key === " ") {
      key = "Spacebar";
    }
    props.vm.currentKeyPressed = key;
    props.vm.keyHistory.push(key);

    setTimeout(() => {
      props.vm.keyHistory.shift();
    }, 3000);
  };

  // return <div>{props.vm.currentKeyPressed}</div>;

  const keyHistory: string[] = [];
  if (props.vm.keyHistory.length > 10) {
    const start = props.vm.keyHistory.length - 10;
    for (let i = start; i < props.vm.keyHistory.length; i++) {
      keyHistory.push(props.vm.keyHistory[i]);
    }
  } else {
    keyHistory.push(...props.vm.keyHistory);
  }
  return (
    <Fragment>
      <div>
        {keyHistory.map((key) => {
          return (
            <div
              style={{
                fontSize: "16px",
                minWidth: "30px",
                padding: "4px",
                textAlign: "center",
              }}
            >
              {key}
            </div>
          );
        })}
      </div>
    </Fragment>
  );
});
