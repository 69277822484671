import MapView from "@arcgis/core/views/MapView";
import Extent from "@arcgis/core/geometry/Extent";
import { AppVM } from "./AppVM";
import { makeAutoObservable } from "mobx";
import { CoursesDataset } from "./CoursesDataset/CoursesDataset";

export class ExtentHandler {
  private readonly appVm: AppVM;
  private readonly mapView: MapView;
  public centerX?: number;
  public centerY?: number;

  constructor(appVm: AppVM) {
    makeAutoObservable(this);
    this.appVm = appVm;
    this.mapView = appVm.mapView!;

    const watchHandle = this.mapView.watch(
      "extent",
      this.onExtentChanged.bind(this)
    );
  }

  currentTimer?: any;
  onExtentChanged(newValue: Extent, oldValue: Extent, propertyName, target) {
    if (this.currentTimer) {
      clearTimeout(this.currentTimer);
    }
    const callback = async () => {
      console.log("currentScale", this.mapView.scale);
      //what to when the time completes
      if (newValue) {
        this.centerX = newValue.center.x;
        this.centerY = newValue.center.y;
        this.appVm.coursesDataset =
          await CoursesDataset.fetchCoursesDatasetByExtent(
            this.mapView.extent.clone().expand(0.15)
          );
        console.log(this.appVm.coursesDataset);

        //TODO: formulate the dynamic text message
        if (this.mapView.scale >= 2256) {
          //display information about the Course
          const course = this.appVm.coursesDataset.courses?.features[0];
          if (course) {
            this.appVm.dynamicMapDescription =
              course.attributes.longDescription;
          } else {
            this.appVm.dynamicMapDescription = "";
          }
        } else if (this.mapView.scale >= 564) {
          //display information about the Hole
          const hole = this.appVm.coursesDataset.holes?.features[0];
          if (hole) {
            const holeNumber = hole.attributes.holeNumber as number;
            this.appVm.dynamicMapDescription = hole.attributes.longDescription;
          } else {
            this.appVm.dynamicMapDescription = "";
          }
        } else {
          //display information about the Basket, or the Tee, and Line

          const hole = this.appVm.coursesDataset.holes?.features[0];
          const tee = this.appVm.coursesDataset.tees?.features[0];
          const basket = this.appVm.coursesDataset.baskets?.features[0];

          if (basket) {
            this.appVm.dynamicMapDescription =
              basket.attributes.longDescription;
          } else if (tee) {
            this.appVm.dynamicMapDescription = tee.attributes.longDescription;
          } else if (hole) {
            const holeNumber = hole.attributes.holeNumber as number;
            this.appVm.dynamicMapDescription = hole.attributes.longDescription;
          } else {
            this.appVm.dynamicMapDescription = "";
          }
        }
      }
    };
    this.currentTimer = setTimeout(callback, 600);
  }
}
