import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { AppVM } from "../../model/AppVM/AppVM";

interface ITextDescriptionProps {
  vm: AppVM;
}

function __TextDescription(props: ITextDescriptionProps) {
  console.log("__TextDescription.render()");
  if (!props.vm) return null;
  if (!props.vm.extentHandler) return null;

  let textItems: string[] = [];
  if (
    !props.vm.dynamicMapDescription ||
    !props.vm.dynamicMapDescription.split
  ) {
    textItems = [];
  } else {
    textItems = props.vm.dynamicMapDescription.split("\n");
  }

  return (
    <Fragment>
      <p style={{ fontSize: "20px" }}>
        {/* {textItems.map((textItem, index) => {
          return <Fragment>{textItem}</Fragment>;
        })} */}
        {props.vm.dynamicMapDescription}
      </p>
    </Fragment>
  );
}

export const TextDescription = observer(__TextDescription);
