import React, { Fragment, useEffect } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import discgolflogo from "./discgolflogo.png";

import { AppVM } from "./model/AppVM";
import { EsriViewHost } from "./components/EsriViewHost";
import {
  Card,
  CardBody,
  CardGroup,
  CardText,
  CardTitle,
  Navbar,
  NavbarBrand,
  NavbarText,
} from "reactstrap";
import { TextDescription } from "./components/TextDescription/TextDescription";
import { NavigationHelp } from "./components/NavigationHelp";
import { observer } from "mobx-react";

// one time call to initialize the application instance
const __appVM = new AppVM();
__appVM.initialize();

function App() {
  let textItems: string[] = [];
  if (!__appVM.dynamicMapDescription || !__appVM.dynamicMapDescription.split) {
    textItems = [];
  } else {
    textItems = __appVM.dynamicMapDescription.split("\n");
  }

  return (
    <div className="App">
      <Navbar color="light" expand="md" fixed="" light className="navbar">
        <img src={discgolflogo} alt="" height={50} width={50} />

        <NavbarText>
          <h1>PI-18 CODEFEST - 508 COMPLIANT MAP</h1>
        </NavbarText>
      </Navbar>

      <div role="main" className="contentContainer">
        <div className="sidePanelContainer" style={{ overflowX: "hidden" }}>
          <Card color="light">
            <CardBody>
              <CardTitle tag="h2">What's in focus?</CardTitle>
              <CardText aria-live="assertive">
                {/* {__appVM.dynamicMapDescription} */}

                {/* {textItems.map((textItem, index) => {
                  return <Fragment>{textItem}</Fragment>;
                })} */}

                <TextDescription vm={__appVM} />
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum. */}
              </CardText>
            </CardBody>
          </Card>

          <Card color="light">
            <CardBody>
              <CardTitle tag="h2">Map Navigation Controls</CardTitle>
              <CardText>
                <NavigationHelp />
              </CardText>
            </CardBody>
          </Card>

          {/* <CardGroup>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Component title</CardTitle>
                <CardText>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </CardText>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Component title</CardTitle>
                <CardText>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </CardText>
              </CardBody>
            </Card>
          </CardGroup> */}
        </div>

        {__appVM.initialized ? (
          <div className="fill-parent relative">
            <EsriViewHost esriView={__appVM.mapView} />
            <div className="crosshair"></div>
          </div>
        ) : (
          <div>loading...</div>
        )}
      </div>
    </div>
  );
}

export default observer(App);
