import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "./constructReactWidget.scss";

export function constructReactWidget(
  ReactComponent: any,
  props: any
): HTMLElement {
  const container = document.createElement("div");
  container.classList.add("react-widget-container");
  ReactDOM.render(<ReactComponent {...props} />, container);
  return container;
}
