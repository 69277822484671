import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import Geometry from "@arcgis/core/geometry/Geometry";
import query, * as esriQuery from "@arcgis/core/rest/query";
import Query from "@arcgis/core/rest/support/Query";

import { makeAutoObservable } from "mobx";

// const __basketsLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/1";
// const __teesLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/2";
// const __pathsLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/3";
// const __obstaclesLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/4";
// const __holesLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/5";
// const __coursesLayerUrl =
//   "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_WFL1/FeatureServer/6";

const __basketsLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/arcgis/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/12";
const __teesLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/arcgis/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/11";
const __pathsLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/arcgis/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/10";
const __obstaclesLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/13";
const __holesLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/9";
const __coursesLayerUrl =
  "https://services7.arcgis.com/1hCPih9sRKKEQwws/ArcGIS/rest/services/PI_18_CodeFest_DiscGolf_Course_2_WFL1/FeatureServer/8";

export class CoursesDataset {
  baskets?: FeatureSet;
  tees?: FeatureSet;
  clearPaths?: FeatureSet;
  obstacles?: FeatureSet;
  holes?: FeatureSet;
  courses?: FeatureSet;

  constructor() {
    makeAutoObservable(this);
  }

  public static async fetchCoursesDatasetByExtent(
    geometry: Geometry
  ): Promise<CoursesDataset> {
    const dataset = new CoursesDataset();

    const queryFilter = new Query();
    queryFilter.geometry = geometry;
    queryFilter.spatialRelationship = "intersects";
    queryFilter.outFields = ["*"];
    queryFilter.returnGeometry = true;
    queryFilter.where = "1=1";

    const pCourses = esriQuery.executeQueryJSON(__coursesLayerUrl, queryFilter);
    const pHoles = esriQuery.executeQueryJSON(__holesLayerUrl, queryFilter);
    const pBaskets = esriQuery.executeQueryJSON(__basketsLayerUrl, queryFilter);
    const pTees = esriQuery.executeQueryJSON(__teesLayerUrl, queryFilter);
    const pLines = esriQuery.executeQueryJSON(__pathsLayerUrl, queryFilter);
    const pObstacles = esriQuery.executeQueryJSON(
      __obstaclesLayerUrl,
      queryFilter
    );

    dataset.courses = await pCourses;
    dataset.holes = await pHoles;
    dataset.baskets = await pBaskets;
    dataset.tees = await pTees;
    dataset.clearPaths = await pLines;
    dataset.obstacles = await pObstacles;

    return dataset;
  }

  public static async fetchCoursesDatasetByCourseId(courseId: number) {
    // throw new Error("Not implemented.");

    const dataset = new CoursesDataset();

    const queryFilter = new Query();
    queryFilter.outFields = ["*"];
    queryFilter.returnGeometry = true;
    queryFilter.where = `OBJECTID = ${courseId}`;

    dataset.courses = await esriQuery.executeQueryJSON(
      __coursesLayerUrl,
      queryFilter
    );

    // const course = dataset.courses.features[0];
    // const courseId = course.attributes.OBJECTID as number;

    queryFilter.where = `courseID = ${courseId}`;
    dataset.holes = await esriQuery.executeQueryJSON(
      __holesLayerUrl,
      queryFilter
    );
    console.log("holes", dataset.holes.features.length);

    queryFilter.where = `courseID = ${courseId}`;
    dataset.tees = await esriQuery.executeQueryJSON(
      __teesLayerUrl,
      queryFilter
    );
    console.log("tees", dataset.tees.features.length);

    queryFilter.where = `courseID = ${courseId}`;
    dataset.baskets = await esriQuery.executeQueryJSON(
      __basketsLayerUrl,
      queryFilter
    );
    console.log("baskets", dataset.baskets.features.length);

    queryFilter.where = `courseID = ${courseId}`;
    dataset.clearPaths = await esriQuery.executeQueryJSON(
      __pathsLayerUrl,
      queryFilter
    );
    console.log("lines", dataset.clearPaths.features.length);

    queryFilter.where = `courseID = ${courseId}`;
    dataset.obstacles = await esriQuery.executeQueryJSON(
      __obstaclesLayerUrl,
      queryFilter
    );
    console.log("obstacles", dataset.obstacles.features.length);

    return dataset;
  }
}
